import React, { useContext } from "react";
import { IoClose } from "react-icons/io5";
import AppContext from "../../../context/AppContext";


const FakultetModal = ({ setShowModal , modalData}) => {
  const { leng } = useContext(AppContext);
  const data = modalData[leng]?.[0];

  if (!data) return null; 

  return (
    <div className="modalFakultet">
      <div className="modalFakultet_closeBtn">
        <button>{data.title}</button>
        <button onClick={() => setShowModal(false)}>
          <IoClose />
        </button>
      </div>
      <ul className="modalFakultet_list">
        <li>
          <p>{leng == 'uz' ? "Shakl:" : leng == 'ru' ? "Форма обучения" : "Type of study"}</p>
          <span></span>
          <b>{data.shakl}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Manzili:" : leng == 'ru' ? "Расположение" : "Main Location"}</p>
          <span></span>
          <b>{data.manzili}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "O‘qishni boshlanishi:" : leng == 'ru' ? "Начало обучения" : "Start of Degree Program"}</p>
          <span></span>
          <b>{data.oqishniboshlanishi}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "O‘qish muddati:" : leng == 'ru' ? "Период обучения" : "Standard Duration of Studies"}</p>
          <span></span>
          <b>{data.oqishmuddati}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Ariza berish muddati:" : leng == 'ru' ? "Крайний срок подачи заявок" : "Application Period"}</p>
          <span></span>
          <b>{data.arizaberishmuddati}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Narhi:" : leng == 'ru' ? "Стоимость обучения" : "Costs"}</p>
          <span></span>
          <b>{data.narxi}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Kredit:" : leng == 'ru' ? "Кредиты" : "Credits"}</p>
          <span></span>
          <b>{data.kredit}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Qabul turi:" : leng == 'ru' ? "Форма приёма" : "Admission Category"}</p>
          <span></span>
          <b>{data.qabulturi}</b>
        </li>
        <li>
          <p>{leng == 'uz' ? "Talab qilinadigan til:" : leng == 'ru' ? "Язык" : "Required Language Proficiency"}</p>
          <span></span>
          <b>{data.talabqilinadigantil}</b>
        </li>
      </ul>
    </div>
  );
};

export default FakultetModal;
