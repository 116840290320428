import React, { useContext, useState } from "react";
import listLineImg from "../shestnna/listLine.svg";
import twoIcon1 from "../fakultetIcons/twoIcon1.svg";
import twoIcon2 from "../fakultetIcons/twoIcon2.svg";
import twoIcon3 from "../fakultetIcons/twoIcon3.svg";
import AppContext from "../../../context/AppContext";

export default function FakultetTwo({ setShowModal, setModalDataValue }) {
  const { leng } = useContext(AppContext);

  const translations = {
    uz: {
      title: "Bitiruvchilar ega bo‘ladigan kompetensiyalar:",
      competencies: [
        "davlat siyosatining dolzarb masalalarini bilish, ijtimoiy-iqtisodiy muammolar va jarayonlarni mustaqil tahlil qila olish;",
        "kasbiy faoliyatda iqtisodiy fanlarning asosiy qonunlaridan foydalana olish, matematik tahlil va modellashtirish, nazarry va eksperimental tadqiqotlar usullarini qo‘llay olish;",
        "xorijiy tillardan birida kasbiy faoliyatiga oid hujjatlar va ishlar mohiyatini tushunish va kasbiy faoliyati uchun yetarli darajada undan foydalana olish;",
        "axborot texnologiyalarini kasbiy faoliyatida qo‘llay bilishi, axborotlarni yig‘ish, saqlash, qayta ishlash va ulardan foydalanish usullarini egallagan bo‘lishi, faoliyatida mustaqil asoslangan qarorlar qabul qila olish;",
        "yangi bilimlarni mustaqil egallay bilish, o‘z ustida ishlashi va mehnat faoliyatini ilmiy asosda tashkil qila olish;",
        "sog‘lom turmush tarzi va unga amal qilish zaruriyati to‘g‘risida tasavvurga ega bo‘lish;",
        "me'yoriy-huquqiy hujjatlarni izlash, tahlil qilish va ulardan kasbiy faoliyatida foydalanish ko‘nikmalariga ega bo‘lish;",
        "yuklarni tashish, ortish, tushirish, omborlarda saqlash, zaxiralarni boshqarish, buyurtmalarni boshqarish, qadoqlash, xaridlarni amalga oshirishda logistika operats iyalari va funksiyalarini optimallashtirish ;",
        "ishlab chiqarishni tashkil etish, tashkiliy tuzilmalarni optimallashtirish, resurslardan samarali foydalanish hamda korxona (tashkilot)ni rivojlantirish strategiyasini qo‘llash, tadbirlarni rejalashtirish va amalga oshirish ko‘nikmalanga ega bo‘lish;",
        "makro va mikro darajadagi logistika tizimlarni loyihalash hamda logistika zanjirlari, logistika bo‘g'unlari, ularda harakatlanadigan moddiy, moliyaviy va axborot oqimlarni boshqarish;",
        "logistikaning funksional sohalari ta'minot, ishlab chiqarish, taqsimot tizimida bajariladigan logistika operatsiyalari va funksiyalarini sifatli bajarish ko‘nikmasiga ega bo‘lish;",
        "iqtisodiy o‘sish, strategik tahlil qilish, raqobatdoshlikni ta'minlashga qaratilgan tashkilot strategiyasida logistikaga oid strategik qarorlarni ishlab chiqish va amalga oshirish ko‘nikmalariga ega bo‘lish;",
        "logistika jarayonlarini modellashtirish, biznes yuritishning iqtisodiy va ijtimoiy sharoitlarini baholash, yangi bozor imkoniyatlarini aniqlash va yangi biznes modellarini shakllantirishni bilish;",
        "logistika kompaniya(tashkilot)larini tashkil etish va takomillashtirish ko‘ nikmalariga ega bo‘ lish.",
      ],
    },
    ru: {
      title: "Компетенции, которыми будут обладать выпускники:",
      competencies: [
        "знать текущие вопросы государственной политики, уметь самостоятельно анализировать социально-экономические проблемы;",
        "уметь использовать основные законы экономической науки в профессиональной деятельности, освоить методы математического анализа и моделирования, теоретических и экспериментальных исследований;",
        "понимать сущность документов и произведений, связанных с профессиональной деятельностью, на одном из иностранных языков и уметь достаточно подробно использовать их для профессиональной деятельности;",
        "уметь использовать информационные технологии в своей профессиональной деятельности, владеть методами сбора, хранения, обработки и использования информации, уметь принимать самостоятельные решения в своей деятельности;",
        "способность самостоятельно приобретать новые знания, работать над собой и организовывать трудовую деятельность на научной основе;",
        "иметь представление о здоровом образе жизни и необходимости его соблюдения;",
        "приобрести навыки поиска, анализа и использования нормативно-правовых документов в своей профессиональной деятельности;",
        "оптимизация логистических операций и функций при транспортировке грузов, погрузке, разгрузке, хранении на складах, увеличении запасов, управлении заказами, упаковке, закупках;",
        "организация производства, оптимизация организационных структур, эффективное использование ресурсов и применение стратегии развития предприятия (организации), планирование и реализация мероприятий;",
        "проектирование логистической системы макро- и микроуровня и управление логистическими цепочками, логистическими связями, материальными, финансовыми и информационными потоками в них;",
        "иметь способность качественно выполнять логистические операции и функции, выполняемые в системе снабжения, производства, распределения функциональных областей логистики;",
        "иметь навыки разработки и реализации стратегических решений, связанных с логистикой, в стратегии организации, направленной на экономический рост, стратегический анализ, обеспечение конкурентоспособности;",
        "знать, как моделировать логистические процессы, оценивать экономические и социальные условия ведения бизнеса, выявлять новые рыночные возможности и создавать новые бизнес-модели;",
        "приобрести навыки организации и совершенствования логистических предприятий (организаций).",
      ],
    },
    en: {
      title: "Competencies that graduates will acquire:",
      competencies: [
        "knowledge of current issues of state policy, the ability to independently analyze socio-economic problems and trends;",
        "be able to use the basic laws of economic sciences in professional activities, master the methods of mathematical analysis and modeling, theoretical and experimental research;",
        "understand the essence of documents and works related to professional activities in one of the foreign languages ​​and be able to use them to a sufficient extent for professional activities;",
        "be able to use information technologies in their professional activities, master the methods of collecting, storing, processing and using information, and be able to make independent, informed decisions in their activities;",
        "the ability to independently acquire new knowledge, work on oneself, and organize work activities on a scientific basis;",
        "to have an idea of ​​​​a healthy lifestyle and the need to adhere to it;",
        "acquire the skills to search for, analyze and use regulatory and legal documents in professional activities;",
        "Optimization of logistics operations and functions in the transportation, loading, unloading, warehousing, inventory management, order management, packaging, and procurement of goods;",
        "to have the skills to organize production, optimize organizational structures, effectively use resources, and apply enterprise (organization) development strategies, plan and implement activities;",
        "Design of logistics systems at the macro and micro levels and management of logistics chains, logistics nodes, and material, financial, and information flows moving within them;",
        "to have the skills to qualitatively perform logistics operations and functions performed in the supply, production, and distribution systems of the functional areas of logistics;",
        "to develop and implement strategic decisions related to logistics in the organization's strategy aimed at ensuring economic growth, strategic analysis, and competitiveness;",
        "Knowledge of modeling logistics processes, assessing the economic and social conditions of doing business, identifying new market opportunities, and forming new business models;",
        "to acquire skills in organizing and improving logistics companies (organizations).",
      ],
    },
  };

  const { title, competencies } = translations[leng];

  const [boxes, setBoxes] = useState([
    {
      id: 1,
      icons: twoIcon1,
      title:
        leng === "uz"
          ? "Asosiy vazifalar"
          : leng === "ru"
          ? "Основные задачи"
          : "Main tasks",
      dec:
        leng === "uz" ? (
          <ul>
            <li>
              Xizmat oqimlari va resurslarini boshqarish va optimallashtirish
            </li>
            <li>Yo‘l harakatini tashkil etish va boshqarish</li>
          </ul>
        ) : leng === "ru" ? (
          <ul>
            <li>Управление и оптимизация сервисных потоков и ресурсов</li>
            <li>Организация и управление движением</li>
          </ul>
        ) : (
          <ul>
            <li>Management and optimization of service flows and resources</li>
            <li>Organization and management of traffic</li>
          </ul>
        ),
    },
    {
      id: 2,
      icons: twoIcon2,
      title:
        leng === "uz"
          ? "Faoliyatlarga quyidagilar kiradi:"
          : leng === "ru"
          ? "Деятельность включает в себя"
          : "Activities include:",
      dec:
        leng === "uz" ? (
          <ul>
            <li>Tizimlarni tashkil etish</li>
            <li>Jarayonlarni tahlil qilish</li>
            <li>Ish samaradorligini oshirish</li>
          </ul>
        ) : leng === "ru" ? (
          <ul>
            <li>Системы организации</li>
            <li>Анализ процессов</li>
            <li>Улучшение эффективности работы</li>
          </ul>
        ) : (
          <ul>
            <li>Organization of systems</li>
            <li>Analysis of processes</li>
            <li>Improving work efficiency</li>
          </ul>
        ),
    },
    {
      id: 3,
      icons: twoIcon3,
      title:
        leng === "uz"
          ? "Qo'llash sohalari"
          : leng === "ru"
          ? "Отрасли применения"
          : "Application areas",
      dec:
        leng === "uz" ? (
          <ul>
            <li>Sanoat</li>
            <li>Transport</li>
            <li>Qishloq xo'jaligi</li>
            <li>Davlat hokimiyati va mahalliy hokimiyat organlari</li>
            <li>Tadqiqot tashkilotlari</li>
            <li>Logistika</li>
          </ul>
        ) : leng === "ru" ? (
          <ul>
            <li>Промышленность</li>
            <li>Транспорт</li>
            <li>Сельское хозяйство</li>
            <li>Органы государственной власти и местного самоуправления</li>
            <li>Научно-исследовательские организации</li>
            <li>Логистику</li>
          </ul>
        ) : (
          <ul>
            <li>Industry</li>
            <li>Transport</li>
            <li>Agriculture</li>
            <li>Government and local government bodies</li>
            <li>Research organizations</li>
            <li>Logistics</li>
          </ul>
        ),
    },
  ]);

  return (
    <div className="FakultetOne FakultetTwo">
      <div className="FakultetOneSection1">
        <div className="FakultetOneSection1Header">
          <div className="FakultetOneSection1HeaderTitles">
            <p>
              {leng === "uz"
                ? "Kasbiy faoliyatining sohalari:"
                : leng === "ru"
                ? "Сферы профессиональной деятельности:"
                : "Areas of professional activity:"}
            </p>
            {leng === "uz" ? (
              <ul>
                <li>Material ishlab chiqarish</li>
                <li>Oborot va xizmatlar ko'rsatish</li>
                <li>Moliyaviy va axborot faoliyati</li>
              </ul>
            ) : leng === "ru" ? (
              <ul>
                <li>Материальное производство</li>
                <li>Оборот и оказание услуг</li>
                <li>Финансовая и информационная деятельность</li>
              </ul>
            ) : (
              <ul>
                <li>Material production</li>
                <li>Turnover and provision of services</li>
                <li>Financial and information activities</li>
              </ul>
            )}
          </div>
          <button
            className="modalButtonOrange modalButtoTwo"
            onClick={() => setShowModal(true) + setModalDataValue(3)}
          >
            <p>
              {leng === "uz"
                ? "Fakultet haqida batafsil"
                : leng === "ru"
                ? "Подробнее о факультете"
                : "More about the faculty"}
            </p>
          </button>
        </div>

        <div className="FakultetTwoBoxes">
          {boxes.map((item) => (
            <div key={item.id} className="FakultetTwoBox">
              <div className="FakultetTwoBoxTexts">
                <p>{item.title}</p>
                <ul>{item.dec}</ul>
              </div>
              <img src={item.icons} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="mashinasozlikCat_1_info_head mashinasozlikCat_1_info_head2 mashinasozlikCat_1_info_head3 mashinasozlikCat_1_info_Two">
        <h1>
          {leng === "uz" ? (
            <>
              <b>Kasbiy</b> faoliyatlarining obyektlari.
            </>
          ) : leng === "ru" ? (
            <>
              <b>Объекты</b> профессиональной деятельности.
            </>
          ) : (
            <>
              <b>Objects</b> of professional activity.
            </>
          )}
        </h1>
        <p>
          {leng === "uz"
            ? "Turli tashkiliy-huquqiy shakldagi tashkilotlar, ishlab chiqarish va xizmat ko‘rsatish korxonalari, hududiy uyushma va birlashmalardagi tovar-moddiy resurslar oqimini hamda tovar-moddiy resurslar oqimiga taalluqli bo‘lgan moliyaviy va axborotlar oqimini boshqarish jarayonlari; davlat va mahalliy boshqaruv qarorlarini amalga oshirish jarayonlari; ta'minot, ishlab chiqarish va ta'qsimot tizimlarida moddiy, moliyaviy va axborot oqimlami tashkil etish va boshqarish; logistika xizmatlari bozorini o‘rganish, ishlab chiqarish, etkazib berish, taqsimlash, transport, texnologik tizimlarni axborot bilan ta'minlashni tashkil etish; sanoat, qishloq xo‘jaligi, transport tashkilotlari, ta'lim muassasalari, ilmiy tadqiqot muassasalari va mahalliy davlat organlarida iqtisodiy faoliyatni ta'minlash; ishlab chiqarish va xizmat ko‘rsatish jarayonlari."
            : leng === "ru"
            ? "Процессы управления движением материальных ресурсов и потоками финансовых и информационных средств, связанных с движением материальных ресурсов, в организациях различных организационно-правовых форм, предприятиях производства и общественного питания, региональных объединениях и объединениях; процессы реализации решений государства и местного самоуправления; организация и управление материальными, финансовыми и информационными потоками в системах снабжения, производства и распределения; организация информационного обеспечения рынка логистических услуг, производства, доставки, распределения, транспорта, технологических систем; обеспечение экономической деятельности в промышленности, сельском хозяйстве, транспортных организациях, образовательных учреждениях, научно-исследовательских учреждениях и местных государственных органах; процедуры производства и обслуживания."
            : "Processes of managing the flow of commodity and material resources in organizations of various organizational and legal forms, production and service enterprises, territorial associations and unions, as well as the flow of financial and information flows related to the flow of commodity and material resources; processes of implementing state and local government decisions; organization and management of material, financial and information flows in supply, production and distribution systems; study of the logistics services market, organization of information provision of production, supply, distribution, transport, technological systems; ensuring economic activity in industrial, agricultural, transport organizations, educational institutions, scientific research institutions and local government bodies; production and service processes."}
        </p>
      </div>
      <div className="mashinasozlikCat_1_info_list mashinasozlikCat_1_info_list2 mashinasozlikCat_1_info_list3">
        <div className="mashinasozlikCat_1_info_list_title">
          <p>{title}</p>
        </div>
        <ul>
          {competencies.map((competency, index) => (
            <li key={index}>
              <img src={listLineImg} alt="" />
              <p>{competency}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
