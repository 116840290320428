import React, { useContext, useState } from "react";
import car from "../car.svg";
import disc from "../disc.svg";
import book from "../book.svg";
import engine from "../engine.svg";
import scale from "../scale.svg";
import borderOne from "../fakultetIcons/borderOnw.svg";
import borderTwo from "../fakultetIcons/borderTwo.svg";
import listLineImg from "../shestnna/listLine.svg";
import FakultetModal from "../modal/FakultetModal";
import AppContext from "../../../context/AppContext";

export default function FakultetOne({
  setShowModal,
  showModal,
  setModalDataValue,
}) {
  const { leng } = useContext(AppContext);
  console.log(leng);

  const translations = {
    uz: {
      title: "Bitiruvchilar ega bo‘ladigan kompetensiyalar:",
      competencies: [
        "Kasbiy faoliyatda tabiiy fanlarning asosiy qonunlaridan foydalana olishi, matematik tahlil va modellashtirish, nazariy va eksperimental tadqiqotlar usullarini qo‘llay olishi;",
        "Og‘zaki va yozma shaklda davlat tili qoida va me'yorlariga amal qilgan holda fikr, mulohaza, gʻoya, taklif, ta'rif va xulosalarni mantiqiy, toʻgʻri, asosli va aniq bayon etish hamda ifodalay olishi;",
        "Xorijiy tillardan birida kasbiy faoliyatiga oid hujjatlar va ishlar mohiyatini tushunish va kasbiy faoliyati uchun yetarli darajada undan foydalana olishi;",
        "Olingan kasbiy tajribani tanqidiy ko‘rib chiqish, oʻz-oʻzini rivojlantirish, malaka oshirish va o‘z kasbiy faoliyatining turi hamda xarakterini oʻzgartirishga qodir boʻlishi;",
        "Ish joyidagi potensial xavflarni yaxshi tushunishi va yuzaga kelishi mumkin boʻlgan baxtsiz hodisalarning oldini ola bilishi;",
        "Axborotlarni yig‘ish, saqlash, qayta ishlash va ulardan foydalanish usullarini bilish va faoliyatida mustaqil asoslangan qarorlar qabul qila olishi;",
        "Loyihalash, ekspluatatsiya qilish, sinash va xizmat koʻrsatish bilan bogʻliq boʻlgan jarayonlarni monitoringi va sifatini baholash usullari va mexanizmlarini ishlab chiqish ko‘nikmasiga ega boʻlishi;",
        "Atrof-muhitni muhofaza qilish va mehnat xavfsizligi talablariga muvofiq jarayonlarni nazorat qilishda ishtirok etish;",
        "Bajarayotgan faoliyati bo‘yicha ish rejasini tuzish va uni bajarish, nazorat qilish va amalga oshirgan ishining natijalarini baholash;",
        "Xorijiy davlatlardagi fan-texnika yutuqlari, maxsus adabiyotlar va boshqa ilmiy-texnik axborotlarni o‘rganish;",
        "Transport vositalarini loyihalash, ekspluatatsiya qilish, sinash, ularga xizmat koʻrsatish bo‘yicha jahon tajribasini oʻrganish, maʼlumotlar yigʻish, tahlil qilish va tizimlashtirish ishlarini amalga oshirish;",
        "Transport vositalarini loyihalash va ishlab chiqish, ulardan foydalanish, sinash, xizmat koʻrsatish va ta'mirlash samaradorligini baholash, tejamkorlik choralarni ishlab chiqish va ularni texnik-iqtisodiy koʻrsatkichlarini hisoblash ko‘nikmalariga ega boʻlishi;",
        "Transport vositalaridan foydalanish davrida aniqlangan muammolarning sabablarini aniqlash va ularni bartaraf etish, shuningdek ularning takrorlanishini oldini olish choralarini ishlab chiqish qobiliyatiga ega boʻlishi kerak.",
      ],
    },
    ru: {
      title: "Компетенции, которыми будут обладать выпускники:",
      competencies: [
        "Способен использовать основные законы естествознания в профессиональной деятельности, применять методы математического анализа и моделирования, теоретических и экспериментальных исследований;",
        "Уметь логически, правильно, аргументировано и ясно излагать и выражать в устной и письменной форме мнения, соображения, идеи, предложения, определения и выводы, соблюдая правила и нормы государственного языка;",
        "Понимать суть документов и произведений, связанных с его профессиональной деятельностью, на одном из иностранных языков и уметь использовать его в достаточной степени для своей профессиональной деятельности;",
        "Критическое рассмотрение профессионального опыта, саморазвитие, профессиональное развитие и иметь возможность менять вид и характер своей профессиональной деятельности;",
        "Уметь понимать потенциальные риски на рабочем месте и предотвращать возможные несчастные случаи;",
        "Знать методы сбора, хранения, обработки и использования информации и уметь принимать самостоятельные решения в своей деятельности;",
        "Иметь возможность разрабатывать методы и механизмы мониторинга и оценки качества процессов, связанных с проектированием, эксплуатацией, тестированием и оказанием услуг;",
        "Участие в контроле процессов в соответствии с требованиями охраны окружающей среды и безопасности труда;",
        "Составление рабочего плана выполняемых работ и его выполнение, контроль и оценка результатов выполненных работ;",
        "Изучение научно-технических достижений зарубежных стран, специальной литературы и другой научно-технической информации;",
        "Изучить мировой опыт проектирования, эксплуатации, испытаний и обслуживания автомобилей, осуществлять сбор, анализ и систематизацию данных;",
        "Иметь навыки проектирования и разработки транспортных средств, оценивать эффективность их использования, испытаний, обслуживания и ремонта, разрабатывать мероприятия по экономии средств и рассчитывать их технико-экономические показатели;",
        "Должен обладать способностью выявлять причины проблем, выявленных в процессе эксплуатации транспортных средств, и устранять их, а также разрабатывать меры по предотвращению их повторения.",
      ],
    },
    en: {
      title: "Competencies that graduates will acquire:",
      competencies: [
        "Be able to use the basic laws of natural sciences in professional activities, apply methods of mathematical analysis and modeling, theoretical and experimental research;",
        "Be able to logically, correctly, reasonably and clearly state and express thoughts, opinions, ideas, proposals, definitions and conclusions, both orally and in writing, following the rules and norms of the state language;",
        "Understand the essence of documents and works related to professional activities in one of the foreign languages ​​and be able to use them to a sufficient extent for professional activities;",
        "Critical review of professional experience, self-development, professional development and be able to change the type and nature of their professional activities;",
        "Have a good understanding of potential hazards in the workplace and be able to prevent possible accidents;",
        "Knowledge of methods of collecting, storing, processing and using information and the ability to make independent, informed decisions in their activities;",
        "Have the skills to develop methods and mechanisms for monitoring and quality assessment of processes related to design, operation, testing, and maintenance;",
        "Participate in monitoring processes in accordance with environmental protection and occupational safety requirements;",
        "To draw up a work plan for the activities performed and to implement it, monitor it and evaluate the results of the work performed;",
        "Study of scientific and technical achievements, special literature and other scientific and technical information in foreign countries;",
        "Study world experience in the design, operation, testing, and maintenance of vehicles, and carry out data collection, analysis, and systematization;",
        "Have the skills to design and develop vehicles, evaluate their operation, testing, maintenance and repair efficiency, develop cost-effective measures and calculate their technical and economic indicators;",
        "Must have the ability to identify and eliminate the causes of problems identified during the operation of vehicles, as well as develop measures to prevent their recurrence.",
      ],
    },
  };

  const { title, competencies } = translations[leng];

  const [boxes, setBoxes] = useState({
    boxesOne: [
      {
        icons: car,
        title:
          leng === "uz"
            ? "Dizayn va ishlab chiqarish"
            : leng === "ru"
            ? "Проектирование и производство"
            : "Design and production",
        dec:
          leng === "uz" ? (
            <span className="">
              Avtotransport vositalarini ishlab chiqish va loyihalash
              <br />
              <br />
              Ishlab chiqarish jarayonlari va texnologiyalari
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Разработка и конструирование транспортных средств
              <br />
              <br />
              Производственные процессы и технологии
            </span>
          ) : (
            <span className="">
              Development and design of vehicles
              <br />
              <br />
              Manufacturing processes and technologies
            </span>
          ),
      },
      {
        icons: disc,
        title:
          leng === "uz"
            ? "Materiallardan samarali foydalanish"
            : leng === "ru"
            ? "Эффективное использование материалов"
            : "Efficient use of materials",
        dec:
          leng === "uz" ? (
            <span className="">
              Resurs iste'molini optimallashtirish
              <br />
              <br />
              Materiallardan foydalanish samaradorligini nazorat qilish va
              yaxshilash
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Оптимизация расхода ресурсов
              <br />
              <br />
              Контроль и повышение эффективности применения материалов
            </span>
          ) : (
            <span className="">
              Optimization of resource consumption
              <br />
              <br />
              Monitoring and improving the efficiency of material use
            </span>
          ),
      },
      {
        icons: book,
        title:
          leng === "uz"
            ? "Faoliyat vositalari va usullari"
            : leng === "ru"
            ? "Средства и методы деятельности"
            : "Means and methods of activity",
        dec:
          leng === "uz" ? (
            <span className="">
              Maqsadga erishish uchun vositalar, usullar va usullardan
              foydalanish inson faoliyati.
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Использование инструментов, методик и способов целенаправленной
              деятельности человека.
            </span>
          ) : (
            <span className="">
              Use of tools, methods and techniques for targeted human activity.
            </span>
          ),
      },
    ],
    boxesTwo: [
      {
        icons: engine,
        title:
          leng === "uz"
            ? "Xizmat, diagnostika va ekspluatatsiya"
            : leng === "ru"
            ? "Обслуживание, диагностика и эксплуатация"
            : "Maintenance, diagnostics and operation",
        dec:
          leng === "uz" ? (
            <span className="spanSize">
              Avtotransport vositalarining ishlashini saqlash
              <br />
              <br />
              Diagnostika choralari
              <br />
              <br />
              Sinov va operatsiya
            </span>
          ) : leng === "ru" ? (
            <span className="spanSize">
              Поддержка работоспособности транспортных средств
              <br />
              <br />
              Диагностические мероприятия
              <br />
              <br />
              Испытания и эксплуатация
            </span>
          ) : (
            <span className="spanSize">
              Maintenance of vehicle performance
              <br />
              <br />
              Diagnostic activities
              <br />
              <br />
              Testing and operation
            </span>
          ),
      },
      {
        icons: scale,
        title:
          leng === "uz"
            ? "Sanoatni rivojlantirish va kasbiy mahorat"
            : leng === "ru"
            ? "Развитие отрасли и профессиональные навыки"
            : "Industry development and professional skills",
        dec:
          leng === "uz" ? (
            <span className="">
              Sanoat istiqbollarini aniqlash
              <br />
              <br />
              Kasbiy va
              <br />
              boshqaruv (kuch) qobiliyatlari
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Определение отраслевых перспектив
              <br />
              <br />
              Формирование профессиональных и
              <br />
              управленческих (властных) навыков
            </span>
          ) : (
            <span className="">
              Determination of industry prospects
              <br />
              <br />
              Development of professional and
              <br />
              managerial (power) skills
            </span>
          ),
      },
    ],
  });

  return (
    <>
      <div className="FakultetOne">
        <div className="FakultetOneSection1">
          <h1>
            {leng === "uz" ? (
              <>
                Kasbiy <br /> faoliyatining <br /> sohalari:
              </>
            ) : leng === "ru" ? (
              <>
                Сферы <br /> профессиональной <br /> деятельности:
              </>
            ) : (
              <>
                Areas of <br /> professional <br /> activity:
              </>
            )}
          </h1>
          <div className="FakultetOneBoxes">
            <div className="FakultetOneBoxesOne">
              {boxes.boxesOne.map((item) => (
                <div className="FakultetOneBox FakultetOneBoxMini">
                  <img src={item.icons} alt="" />
                  <p>{item.title}</p>
                  {item.dec}
                </div>
              ))}
            </div>
            <div className="FakultetOneBoxesOne FakultetOneBoxesTwo">
              {boxes.boxesTwo.map((item) => (
                <div className="FakultetOneBox FakultetOneBoxBig">
                  <img src={item.icons} alt="" />
                  <p>{item.title}</p>
                  {item.dec}
                </div>
              ))}
              <button
                className="modalButtonOrange"
                onClick={() => setShowModal(true) + setModalDataValue(2)}
              >
                <p>
                  {leng === "uz"
                    ? "Fakultet haqida batafsil"
                    : leng === "ru"
                    ? "Подробнее о факультете"
                    : "More about the faculty"}
                </p>
              </button>
            </div>
          </div>
          <div className="FakultetOneSectio1Borders">
            <img src={borderOne} alt="" className="borderOne" />
            <img src={borderTwo} alt="" className="borderTwo" />
          </div>
        </div>
        <div className="mashinasozlikCat_1_info_head mashinasozlikCat_1_info_head2">
          <h1>
            {leng === "uz" ? (
              <>
                <b>Kasbiy</b> faoliyatlarining obyektlari.
              </>
            ) : leng === "ru" ? (
              <>
                <b>Объекты</b> профессиональной деятельности.
              </>
            ) : (
              <>
                <b>Objects</b> of professional activity.
              </>
            )}
          </h1>
          <p>
            {leng === "uz"
              ? "Davlat va nodavlat tashkilot, korxona va muassasalar, kompaniyalar (firmalar), ishlab chiqarish birlashmalari va soha korxonalarida muhandis-mexanik."
              : leng === "ru"
              ? "Инженер-механик в государственных и негосударственных организациях, предприятиях и учреждениях, компаниях (фирмах), производственных объединениях и предприятиях промышленности."
              : "Mechanical engineer in state and non-state organizations, enterprises and institutions, companies (firms), production associations and industry enterprises."}
          </p>
        </div>
        <div className="mashinasozlikCat_1_info_list mashinasozlikCat_1_info_list2">
          <div className="mashinasozlikCat_1_info_list_title">
            <p>{title}</p>
          </div>
          <ul>
            {competencies.map((competency, index) => (
              <li key={index}>
                <img src={listLineImg} alt="" />
                <p>{competency}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
