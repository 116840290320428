import React, { useState } from 'react';
import './qoidalar.css'
import { useContext } from 'react';
import AppContext from '../../context/AppContext';

const Qoidalar = () => {

    const { leng } = useContext(AppContext)

    const [data, setData] = useState([
        {
            id: 1,
            text: "TA’LIM TO‘G‘RISIDA",
            text_en: "About education",
            url: "https://lex.uz/docs/-5013007"
        },
        {
            id: 2,
            text: "OLIY TA’LIM MUASSASALARIGA O‘QISHGA QABUL QILISH, TALABALAR O‘QISHINI KO‘CHIRISH, QAYTA TIKLASH VA O‘QISHDAN CHETLASHTIRISH TARTIBI TO‘G‘RISIDAGI NIZOMLARNI TASDIQLASH HAQIDA",
            text_en: "On approval of regulations on the procedure for admission to higher education institutions, transfer, reinstatement and expulsion of students",
            url: "https://lex.uz/docs/3244181"
        },
        {
            id: 3,
            text: "824 - OLIY TA’LIM MUASSASALARIDA TA’LIM JARAYONINI TASHKIL ETISH BILAN BOG‘LIQ TIZIMNI TAKOMILLASHTIRISH CHORA-TADBIRLARI TO‘G‘RISIDA",
            text_en: " On measures to improve the system related to the organization of the educational process in higher educational institutions",
            url: "https://lex.uz/docs/-5193564"
        },
        {
            id: 4,
            text: "OLIY TA’LIMNING DAVLAT TA’LIM STANDARTI. ASOSIY QOIDALAR” O‘ZBEKISTON RESPUBLIKASINING DAVLAT STANDARTINI TASDIQLASH TO‘G‘RISIDA",
            text_en: "On approval of the state standard of the Republic of Uzbekistan State educational standard of higher education. Basic provisions",
            url: "https://lex.uz/docs/-5705038"
        },
        {
            id: 5,
            text: "O‘ZBEKISTON RESPUBLIKASI OLIY TA’LIM MUASSASALARI TALABALARIGA AKADEMIK TA’TIL BERISH TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA",
            text_en: " On approval of the Regulation on granting academic leave to students of higher educational institutions of the Republic of Uzbekistan",
            url: "https://lex.uz/docs/-5443081"
        },
        {
            id: 6,
            text: "OLIY TA’LIM MUASSASALARIDA TALABALAR BILIMINI NAZORAT QILISH VA BAHOLASH TIZIMI TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA",
            text_en: "On approval of the Regulation on the system of monitoring and evaluating student knowledge in higher education institutions",
            url: "https://lex.uz/docs/3916793"
        },
        {
            id: 7,
            text: " VM- 930-son. Qaror. 21.11.2017 yildagi - OLIY TA’LIM MUASSASASIDA SIRTQI (MAXSUS SIRTQI) VA KECHKI (SMENALI) TA’LIMNI TASHKIL ETISH TARTIBI TO‘G‘RISIDAGI NIZOMLARNI TASDIQLASH HAQIDA",
            text_en: "On approval of the regulations on the procedure for organizing correspondence (special correspondence) and evening (shift) education in higher education institutions",
            url: "https://lex.uz/docs/3420313"
        },
        {
            id: 8,
            text: "MAGISTRATURA TO‘G‘RISIDAGI NIZOMNI TASDIQLASH HAQIDA",
            text_en: " On approval of the Regulations on Magistracy",
            url: "https://lex.uz/docs/-2579469"
        },
        {
            id: 9,
            text: "O‘ZBEKISTON RESPUBLIKASI OLIY TA’LIM TIZIMINI 2030 YILGACHA RIVOJLANTIRISH KONSEPSIYASINI TASDIQLASH TO‘G‘RISIDA",
            text_en: "On approval of the Concept for the Development of the Higher Education System of the Republic of Uzbekistan until 2030",
            url: "https://lex.uz/docs/4545884"
        },
        {
            id: 10,
            text: "O‘ZBEKISTON RESPUBLIKASI OLIY TA’LIM TIZIMIDA MAGISTRATURA FAOLIYATINI YANADA TAKOMILLASHTIRISH, UNING SAMARADORLIGINI OSHIRISH CHORA-TADBIRLARI TO‘G‘RISIDA",
            text_en: "ON MEASURES TO FURTHER IMPROVE THE ACTIVITIES OF THE MASTER'S PROGRAM IN THE HIGHER EDUCATION SYSTEM OF THE REPUBLIC OF UZBEKISTAN AND INCREASE ITS EFFICIENCY",
            url: "https://lex.uz/docs/1248937"
        }
    ])

    return (
        <div className='qoidalar'>
            <h1>{leng == 'uz' ? "Ta’lim tog‘risidagi hujjatlar" : leng == 'ru' ? "Ta’lim tog‘risidagi hujjatlar" : "Documents on education"}</h1>
            {
                data.map(item => (
                    <div className='qoidalar_box'>
                        <span>{item.id}.</span>
                        <p>{leng == 'uz' ? item.text : leng == 'ru' ? item.text : item.text_en}</p>
                        <a href={item.url}>{item.url}</a>
                    </div>
                ))
            }
            <p></p>
        </div>
    );
};

export default Qoidalar;