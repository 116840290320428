import React, { useContext, useState } from "react";
import "./fakultet.css";
import bgImage from "./bg.jpg";
import bgBtn from "./btnBg.svg";
import bgBtnBefore from "./btnBgBefore.svg";
import { IoClose } from "react-icons/io5";
import Line from "./shestnna/line.svg";
import rounded from "./shestnna/rounded.svg";
import whitelineshet from "./shestnna/whitelineshet.svg";
import redLineshet from "./shestnna/redLineshet.svg";
import roundedRed from "./shestnna/roundedRed.svg";
import redsheet2 from "./shestnna/redsheet2.svg";
import white1 from "./shestnna/white1.svg";
import whiteSheet2 from "./shestnna/whiteshet2.svg";
import FakultetOne from "./components/FakultetOne";
import FakultetTwo from "./components/FakultetTwo";
import FakultetThree from "./components/FakultetThree";
import titleImg from "./shestnna/descImg.png";
import listLineImg from "./shestnna/listLine.svg";
import Footer from "../../components/footer/Footer";
import FakultetModal from "./modal/FakultetModal";
import AppContext from "../../context/AppContext";

const Fakultet = () => {
  const { leng } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [showDirections, setShowDirections] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalDataValue, setModalDataValue] = useState(1);

  const handleClick = (index) => {
    if (
      index !== 0 &&
      index !== 5 &&
      index !== 4 &&
      index !== 3 &&
      index !== 2
    ) {
      setActiveIndex(index);
      setSelectedDirection(null);
      setShowDirections(true);
    }
  };

  const handleDirectionClick = (direction, index) => {
    setSelectedDirection({ name: direction, id: index });
    setShowDirections(false);
  };

  const handleClose = () => {
    setSelectedDirection(null);
    setShowDirections(true);
  };

  const directions = {
    1: [
      leng === "uz"
        ? "60720400-Texnologik mashinalar va jihozlar” bakalavriyat ta`lim yo‘nalishi."
        : leng === "ru"
        ? "60720400-Образование высшее «Технологические машины и оборудование»"
        : "60720400-Technological machines and equipment bachelor's degree program.",
      leng === "uz"
        ? "60711400-Transport vositalari muhandisligi” bakalavriyat ta`lim yo‘nalishi. "
        : leng === "ru"
        ? "60711400-Образование высшее «Автомобильное дело»."
        : "60711400-Vehicle Engineering bachelor's degree program.",
      leng === "uz"
        ? "61010400 - Logistika” bakalavriyat ta`lim yo‘nalishi. "
        : leng === "ru"
        ? "61010400 - Бакалавриат по направлению «Логистика»"
        : "61010400 - Bachelor's degree in Logistics.",
      leng === "uz"
        ? "70712301-Mashinasozlik texnologiyasi” magistratura mutaxassisligi. "
        : leng === "ru"
        ? "70712301-Специальность магистра «Технология машиностроения»"
        : "70712301-Master's degree in Mechanical Engineering Technology",
    ],
    2: [
      "60810100 - Архитектура и градостроительство",
      "60820100 - Дизайн интерьера",
      "60820200 - Ландшафтная архитектура",
    ],
    3: [
      "60930100 - Информационные технологии",
      "60930200 - Кибербезопасность",
      "60930300 - Искусственный интеллект",
    ],
    4: [
      "60510100 - Экономика и управление",
      "60510200 - Финансы и бухгалтерский учет",
    ],
  };

  const modalData = {
    uz: [
      {
        id: 1,
        title:
          "60720400- Texnologik mashinalar va jihozlar (mashinasozlik va metallga ishlov berish bo‘yicha)",
        shakl: "Kunduzgi/Sirtqi",
        manzili: "Urganch sh., Xonqa, 26",
        oqishniboshlanishi: "Kuzgi semestr",
        oqishmuddati: "4 yil (kunduzgi)/5 yil (sirtqi)",
        arizaberishmuddati: "Kuzgi semestr: 17.03-30.09",
        narxi: "17 002 750 so‘m (kunduzgi)/14 610 400 so‘m (sirtqi)",
        kredit: "240 ECTS",
        qabulturi: "Bakalavriat uchun bilimini baholash",
        talabqilinadigantil: "O‘zbek tili",
      },
    ],
    ru: [
      {
        id: 1,
        title:
          "60720400- Технологические машины и оборудование (машиностроение и металлообработка)",
        shakl: "Очное/Заочное",
        manzili: "г. Ургенч, ул. Ханкинская, 26",
        oqishniboshlanishi: "Осенний семестр",
        oqishmuddati: "4 года (очное)/5 лет (заочное)",
        arizaberishmuddati: "Осенний семестр: 17.03-30.09",
        narxi:
          "17 002 750 узбекских сум (очное)/14 610 400 узбекских сум (заочное)",
        kredit: "240 ECTS",
        qabulturi: "Оценка знаний для поступления на бакалавриат",
        talabqilinadigantil: "Узбекский",
      },
    ],
    en: [
      {
        id: 1,
        title:
          "BSc in Technological Machines and Equipment (Mechanical Engineering and Metal Industry)",
        shakl: "Full-time/Part-time",
        manzili: "Urgench city, Khanka str., 26",
        oqishniboshlanishi: "Winter semester",
        oqishmuddati: "4 years (full-time)/5 years (part-time)",
        arizaberishmuddati: "Winter semester: 17.03-30.09",
        narxi: "17 002 750 UZS (full-time)/14 610 400 UZS (part-time)",
        kredit: "240 ECTS",
        qabulturi: "Apptitute Assessment for Bachelor",
        talabqilinadigantil: "Uzbek",
      },
    ],
  };

  const modalData2 = {
    uz: [
      {
        id: 1,
        title:
          "60711400- Transport vositalari muhandisligi (avtomobil transporti bo‘yicha)",
        shakl: "Kunduzgi/Sirtqi",
        manzili: "Urganch sh., Xonqa, 26",
        oqishniboshlanishi: "Kuzgi semestr",
        oqishmuddati: "4 yil (kunduzgi)/5 yil (sirtqi)",
        arizaberishmuddati: "Kuzgi semestr: 17.03-30.09",
        narxi: "18 032 000 so‘m (kunduzgi)/14 560 000 so‘m (sirtqi)",
        kredit: "240 ECTS",
        qabulturi: "Bakalavriat uchun bilimini baholash",
        talabqilinadigantil: "O‘zbek tili",
      },
    ],
    ru: [
      {
        id: 1,
        title: "60711400- Инженерное дело в области транспортных средств",
        shakl: "Очное/Заочное",
        manzili: "г. Ургенч, ул. Ханкинская, 26",
        oqishniboshlanishi: "Осенний семестр",
        oqishmuddati: "4 года (очное)/5 лет (заочное)",
        arizaberishmuddati: "Осенний семестр: 17.03-30.09",
        narxi:
          "18 032 000 узбекских сум (очное)/14 560 000 узбекских сум (заочное)",
        kredit: "240 ECTS",
        qabulturi: "Оценка знаний для поступления на бакалавриат",
        talabqilinadigantil: "Узбекский",
      },
    ],
    en: [
      {
        id: 1,
        title:
          "BSc in Automative Engineering (specializing in Automobile Transport)",
        shakl: "Full-time/Part-time",
        manzili: "Urgench city, Khanka str., 26",
        oqishniboshlanishi: "Winter semester",
        oqishmuddati: "4 years (full-time)/5 years (part-time)",
        arizaberishmuddati: "Winter semester: 17.03-30.09",
        narxi: "Costs: 18 032 000 UZS (full-time)/14 560 000 UZS (part-time)",
        kredit: "240 ECTS",
        qabulturi: "Apptitute Assessment for Bachelor",
        talabqilinadigantil: "Uzbek",
      },
    ],
  };

  const modalData3 = {
    uz: [
      {
        id: 1,
        title: "61010400- Logistika (avtomobil transporti)",
        shakl: "Kunduzgi/Sirtqi",
        manzili: "Urganch sh., Xonqa, 26",
        oqishniboshlanishi: "Kuzgi semestr",
        oqishmuddati: "4 yil (kunduzgi)/5 yil (sirtqi)",
        arizaberishmuddati: "Kuzgi semestr: 17.03-30.09",
        narxi: "18 032 000 so‘m (kunduzgi)/14 560 000 so‘m (sirtqi)",
        kredit: "240 ECTS",
        qabulturi: "Bakalavriat uchun bilimini baholash",
        talabqilinadigantil: "O‘zbek tili",
      },
    ],
    ru: [
      {
        id: 1,
        title: "61010400-Логистика (автомобильный транспорт)",
        shakl: "Очное/Заочное",
        manzili: "г. Ургенч, ул. Ханкинская, 26",
        oqishniboshlanishi: "Осенний семестр",
        oqishmuddati: "4 года (очное)/5 лет (заочное)",
        arizaberishmuddati: "Осенний семестр: 17.03-30.09",
        narxi:
          "18 032 000 узбекских сум (очное)/14 560 000 узбекских сум (заочное)",
        kredit: "240 ECTS",
        qabulturi: "Оценка знаний для поступления на бакалавриат",
        talabqilinadigantil: "Узбекский",
      },
    ],
    en: [
      {
        id: 1,
        title: "BSc in Logistics (Road Transport)",
        shakl: "Full-time/Part-time",
        manzili: "Urgench city, Khanka str., 26",
        oqishniboshlanishi: "Winter semester",
        oqishmuddati: "4 years (full-time)/5 years (part-time)",
        arizaberishmuddati: "Winter semester: 17.03-30.09",
        narxi: "Costs: 18 032 000 UZS (full-time)/14 560 000 UZS (part-time)",
        kredit: "240 ECTS",
        qabulturi: "Apptitute Assessment for Bachelor",
        talabqilinadigantil: "Uzbek",
      },
    ],
  };

  const modalData4 = {
    uz: [
      {
        id: 1,
        title:
          "70712301- Mashinasozlik texnologiyalari va uskunalari (ishlab chiqarish bo'yicha)",
        shakl: "Kunduzgi",
        manzili: "Urganch sh., Xonqa, 26",
        oqishniboshlanishi: "Kuzgi semestr",
        oqishmuddati: "2 yil (kunduzgi)",
        arizaberishmuddati: "Kuzgi semestr: 17.03-30.09",
        narxi: "19 824 000 so‘m ",
        kredit: "120 ECTS",
        qabulturi: "Magistratura uchun bilimini baholash",
        talabqilinadigantil: "O‘zbek tili",
      },
    ],
    ru: [
      {
        id: 1,
        title:
          "70712301-Технологии и оборудование машиностроения (по производству)",
        shakl: "Очное",
        manzili: "г. Ургенч, ул. Ханкинская, 26",
        oqishniboshlanishi: "Осенний семестр",
        oqishmuddati: "2 года (очное)",
        arizaberishmuddati: "Осенний семестр: 17.03-30.09",
        narxi: "19 824 000 узбекских сум",
        kredit: "120 ECTS",
        qabulturi: "Оценка знаний для поступления на магистратуру",
        talabqilinadigantil: "Узбекский",
      },
    ],
    en: [
      {
        id: 1,
        title:
          "MSc in Mechanical Engineering Technologies and Equipment (by Production)",
        shakl: "Full-time",
        manzili: "Urgench city, Khanka str., 26",
        oqishniboshlanishi: "Winter semester",
        oqishmuddati: "2 years (full-time)",
        arizaberishmuddati: "Winter semester: 17.03-30.09",
        narxi: "19 824 000 UZS",
        kredit: "120 ECTS",
        qabulturi: "Apptitute Assessment for Master",
        talabqilinadigantil: "Uzbek",
      },
    ],
  };

  const btns = [
    {
      id: 1,
      title_uz: "Innovatsion texnologiyalar fakulteti",
      title_ru: "Факультет инновационных технологий",
      title_en: "Faculty of Innovative Technologies",
    },
    {
      id: 2,
      title_uz: "Innovatsion texnologiyalar fakulteti",
      title_ru: "Факультет инновационных технологий",
      title_en: "Faculty of Innovative Technologies",
    },
    {
      id: 3,
      title_uz: "Iqtisodiyot fakulteti",
      title_ru: "Факультет экономики",
      title_en: "Faculty of Economics",
    },
    {
      id: 4,
      title_uz: "Pedagogika va filologiya fakulteti",
      title_ru: "Факультет педагогики и филологии",
      title_en: "Faculty of Pedagogy and Philology",
    },
    {
      id: 5,
      title_uz: "Tibbiyot fanlari fakulteti",
      title_ru: "Факультет медицинских наук",
      title_en: "Faculty of Medical Sciences",
    },
    {
      id: 6,
      title_uz: "Innovatsion texnologiyalar fakulteti",
      title_ru: "Факультет инновационных технологий",
      title_en: "Faculty of Innovative Technologies",
    },
  ];

  const translations = {
    uz: {
      title: "Bitiruvchilar ega bo‘ladigan kompetensiyalar:",
      competencies: [
        "Kasbiy faoliyatda tabiiy fanlarning asosiy qonunlaridan foydalana olishi, matematik tahlil va modellashtirish, nazariy va eksperimental tadqiqotlar usullarini qo‘llay olishi;",
        "Og‘zaki va yozma shaklda davlat tili qoida va me'yorlariga amal qilgan holda fikr, mulohaza, gʻoya, taklif, ta'rif va xulosalarni mantiqiy, toʻgʻri, asosli va aniq bayon etish hamda ifodalay olishi;",
        "Xorijiy tillardan birida kasbiy faoliyatiga oid hujjatlar va ishlar mohiyatini tushunish va kasbiy faoliyati uchun yetarli darajada undan foydalana olishi;",
        "Olingan kasbiy tajribani tanqidiy ko‘rib chiqish, oʻz-oʻzini rivojlantirish, malaka oshirish va o‘z kasbiy faoliyatining turi hamda xarakterini oʻzgartirishga qodir boʻlishi;",
        "Ish joyidagi potensial xavflarni yaxshi tushunishi va yuzaga kelishi mumkin boʻlgan baxtsiz hodisalarning oldini ola bilishi;",
        "Axborotlarni yig‘ish, saqlash, qayta ishlash va ulardan foydalanish usullarini bilish va faoliyatida mustaqil asoslangan qarorlar qabul qila olishi;",
        "Loyihalash, ekspluatatsiya qilish, sinash va xizmat koʻrsatish bilan bogʻliq boʻlgan jarayonlarni monitoringi va sifatini baholash usullari va mexanizmlarini ishlab chiqish ko‘nikmasiga ega boʻlishi;",
        "Atrof-muhitni muhofaza qilish va mehnat xavfsizligi talablariga muvofiq jarayonlarni nazorat qilishda ishtirok etish;",
        "Bajarayotgan faoliyati bo‘yicha ish rejasini tuzish va uni bajarish, nazorat qilish va amalga oshirgan ishining natijalarini baholash;",
        "Xorijiy davlatlardagi fan-texnika yutuqlari, maxsus adabiyotlar va boshqa ilmiy-texnik axborotlarni o‘rganish;",
        "Transport vositalarini loyihalash, ekspluatatsiya qilish, sinash, ularga xizmat koʻrsatish bo‘yicha jahon tajribasini oʻrganish, maʼlumotlar yigʻish, tahlil qilish va tizimlashtirish ishlarini amalga oshirish;",
        "Transport vositalarini loyihalash va ishlab chiqish, ulardan foydalanish, sinash, xizmat koʻrsatish va ta'mirlash samaradorligini baholash, tejamkorlik choralarni ishlab chiqish va ularni texnik-iqtisodiy koʻrsatkichlarini hisoblash ko‘nikmalariga ega boʻlishi;",
        "Transport vositalaridan foydalanish davrida aniqlangan muammolarning sabablarini aniqlash va ularni bartaraf etish, shuningdek ularning takrorlanishini oldini olish choralarini ishlab chiqish qobiliyatiga ega boʻlishi kerak.",
      ],
    },
    ru: {
      title: "Компетенции, которыми будут обладать выпускники:",
      competencies: [
        "Способен использовать основные законы естествознания в профессиональной деятельности, применять методы математического анализа и моделирования, теоретических и экспериментальных исследований;",
        "Уметь логически, правильно, аргументировано и ясно излагать и выражать в устной и письменной форме мнения, соображения, идеи, предложения, определения и выводы, соблюдая правила и нормы государственного языка;",
        "Понимать суть документов и произведений, связанных с его профессиональной деятельностью, на одном из иностранных языков и уметь использовать его в достаточной степени для своей профессиональной деятельности;",
        "Критическое рассмотрение профессионального опыта, саморазвитие, профессиональное развитие и иметь возможность менять вид и характер своей профессиональной деятельности;",
        "Уметь понимать потенциальные риски на рабочем месте и предотвращать возможные несчастные случаи;",
        "Знать методы сбора, хранения, обработки и использования информации и уметь принимать самостоятельные решения в своей деятельности;",
        "Иметь возможность разрабатывать методы и механизмы мониторинга и оценки качества процессов, связанных с проектированием, эксплуатацией, тестированием и оказанием услуг;",
        "Участие в контроле процессов в соответствии с требованиями охраны окружающей среды и безопасности труда;",
        "Составление рабочего плана выполняемых работ и его выполнение, контроль и оценка результатов выполненных работ;",
        "Изучение научно-технических достижений зарубежных стран, специальной литературы и другой научно-технической информации;",
        "Изучить мировой опыт проектирования, эксплуатации, испытаний и обслуживания автомобилей, осуществлять сбор, анализ и систематизацию данных;",
        "Иметь навыки проектирования и разработки транспортных средств, оценивать эффективность их использования, испытаний, обслуживания и ремонта, разрабатывать мероприятия по экономии средств и рассчитывать их технико-экономические показатели;",
        "Должен обладать способностью выявлять причины проблем, выявленных в процессе эксплуатации транспортных средств, и устранять их, а также разрабатывать меры по предотвращению их повторения.",
      ],
    },
    en: {
      title: "Competencies that graduates will acquire:",
      competencies: [
        "Be able to use the basic laws of natural sciences in professional activities, apply methods of mathematical analysis and modeling, theoretical and experimental research;",
        "Be able to logically, correctly, reasonably and clearly state and express thoughts, opinions, ideas, proposals, definitions and conclusions, both orally and in writing, following the rules and norms of the state language;",
        "Understand the essence of documents and works related to professional activities in one of the foreign languages ​​and be able to use them to a sufficient extent for professional activities;",
        "Critical review of professional experience, self-development, professional development and be able to change the type and nature of their professional activities;",
        "Have a good understanding of potential hazards in the workplace and be able to prevent possible accidents;",
        "Knowledge of methods of collecting, storing, processing and using information and the ability to make independent, informed decisions in their activities;",
        "Have the skills to develop methods and mechanisms for monitoring and quality assessment of processes related to design, operation, testing, and maintenance;",
        "Participate in monitoring processes in accordance with environmental protection and occupational safety requirements;",
        "To draw up a work plan for the activities performed and to implement it, monitor it and evaluate the results of the work performed;",
        "Study of scientific and technical achievements, special literature and other scientific and technical information in foreign countries;",
        "Study world experience in the design, operation, testing, and maintenance of vehicles, and carry out data collection, analysis, and systematization;",
        "Have the skills to design and develop vehicles, evaluate their operation, testing, maintenance and repair efficiency, develop cost-effective measures and calculate their technical and economic indicators;",
        "Must have the ability to identify and eliminate the causes of problems identified during the operation of vehicles, as well as develop measures to prevent their recurrence.",
      ],
    },
  };

  const { title, competencies } = translations[leng];

  return (
    <>
      <h1 className="title_fakultet">
        {leng == "uz"
          ? "Fakultetlar"
          : leng == "ru"
          ? "Факультеты"
          : "Faculties"}
      </h1>
      {showModal && (
        <FakultetModal
          modalData={
            modalDataValue == 1
              ? modalData
              : modalDataValue == 2
              ? modalData2
              : modalDataValue == 3
              ? modalData3
              : modalData4
          }
          setShowModal={setShowModal}
        />
      )}
      <div
        className="fakultetCon"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="fakultetConBtns">
          {btns.map((item, index) => (
            <button
              key={index}
              id={`activeBtn${index}`}
              className={`fakultetConBtn fakultetConBtn-${
                activeIndex !== null ? activeIndex : "default"
              }`}
              style={{
                backgroundImage: `url(${
                  activeIndex === null || activeIndex === index
                    ? bgBtn
                    : bgBtnBefore
                })`,
                cursor: index === 0 || index === 5 ? "default" : "pointer",
              }}
              onClick={() => handleClick(index)}
            >
              <p>{item[`title_${leng}`]}</p>
              <p>
                {directions[index] ? directions[index].length : 0}
                {leng === "uz"
                  ? "yo'nalishlari"
                  : leng === "ru"
                  ? "направлений"
                  : "directions"}
                
              </p>
            </button>
          ))}
        </div>
        {activeIndex !== null && directions[activeIndex] && showDirections && (
          <div key={activeIndex} className="categoryBtnsChil">
            {directions[activeIndex].map((item, i) => (
              <button
                key={i}
                onClick={() => handleDirectionClick(item, i)}
                style={{
                  backgroundColor:
                    selectedDirection?.name === item ? "#FB3505" : "",
                  color: selectedDirection?.name === item ? "#fff" : "",
                }}
              >
                {item}
              </button>
            ))}
          </div>
        )}
        {selectedDirection && (
          <div className="categoryBtnsChil">
            <div className="categoryBtnsChilInner">
              <button style={{ backgroundColor: "#FB3505", color: "#fff" }}>
                {selectedDirection.name}
              </button>
              <button onClick={handleClose} className="closeBtn">
                X
              </button>
            </div>
          </div>
        )}
        {selectedDirection && (
          <div>
            {selectedDirection.id === 0 && (
              <div className="mashinasozlikCat_1_con">
                <div className="mashinasozlikCat_1">
                  <div className="testDivMashinasozlik">
                    <p className="mashinasozlikCat_1_text1">
                      {leng === "uz"
                        ? "Ta'limda ishlab chiqarish va qayta ishlash yo'nalishi dizayn, foydalanish va texnik xizmat ko'rsatishni o'z ichiga oladi texnologik mashinalar, tadqiqot va takomillashtirish ishlab chiqarish jarayonlari, shuningdek, rivojlanish professional va boshqaruv qobiliyatlari"
                        : leng === "ru"
                        ? "Производственно-перерабатывающее направление в образовании охватывает проектирование, эксплуатацию и обслуживание технологических машин, исследование и улучшение производственных процессов, а также развитие профессиональных и управленческих навыков"
                        : "The production and processing direction in education covers the design, operation and maintenance of technological machines, research and improvement of production processes, as well as the development of professional and managerial skills"}
                    </p>
                    <div>
                      <img id="line" src={Line} alt="" />
                      <img id="rounded" src={rounded} alt="" />
                      <img id="whitelineshet" src={whitelineshet} alt="" />
                      <img id="redline" src={redLineshet} alt="" />
                      <img id="roundedRd" src={roundedRed} alt="" />
                      <img id="redshet2" src={redsheet2} alt="" />
                      <img id="redshet3" src={redsheet2} alt="" />
                      <img id="redshet4" src={redLineshet} alt="" />
                      <img id="redshet5" src={redsheet2} alt="" />
                      <img id="roundedRd2" src={roundedRed} alt="" />
                      <img id="roundedRd3" src={roundedRed} alt="" />
                      <img id="roundedRd4" src={roundedRed} alt="" />
                      <img id="roundedRd5" src={roundedRed} alt="" />
                      <img id="white1" src={white1} alt="" />
                      <img id="whitelineshet2" src={whitelineshet} alt="" />
                      <img id="rounded2" src={rounded} alt="" />
                      <img id="whitesheet2" src={whiteSheet2} alt="" />
                      <img id="rounded3" src={rounded} alt="" />
                      <img id="whitesheet3" src={whiteSheet2} alt="" />
                      <img id="rounded4" src={rounded} alt="" />
                    </div>
                    <div className="mashinasozlikCat_1_redText">
                      <p>
                        {leng === "uz"
                          ? "Texnologik mashinalarni loyihalash va ishlab chiqarish va Uskunalar asboblar to'plamini o'z ichiga oladi, inson faoliyatining usullari, usullari va vositalari; samaradorligini oshirish va nazorat qilishga qaratilgan foydalanish, sanoat istiqbolini aniqlash, hokimiyat organlarining kasbiy mahorati va qobiliyatlari."
                          : leng === "ru"
                          ? "Проектирование и производство технологических машин и оборудования включает в себя совокупность инструментов, методов, способов и способов деятельности человека, направленных на повышение и контроль эффективности их использования, определение отраслевой перспективы, профессиональных навыков и способностей власти."
                          : "Design and production of technological machines and equipment includes a set of tools, methods, ways and means of human activity, aimed at increasing and monitoring the efficiency of their use, determining the industry perspective, professional skills and abilities of the authorities."}
                      </p>
                    </div>
                    <button
                      onClick={() => setShowModal(true) + setModalDataValue(1)}
                      className="fakultetOpenModal_btn"
                    >
                      {leng === "uz"
                        ? "Fakultet haqida batafsil"
                        : leng === "ru"
                        ? "Подробнее о факультете"
                        : "More about the faculty"}
                    </button>
                  </div>
                </div>
                <div className="mashinasozlikCat_1_info">
                  <div className="mashinasozlikCat_1_info_head">
                    <h1>
                      {leng === "uz" ? (
                        <>
                          <b>Kasbiy</b> faoliyatlarining obyektlari.
                        </>
                      ) : leng === "ru" ? (
                        <>
                          <b>Объекты</b> профессиональной деятельности.
                        </>
                      ) : (
                        <>
                          <b>Objects</b> of professional activity.
                        </>
                      )}
                    </h1>
                    <p>
                      {leng === "uz"
                        ? "Davlat va nodavlat tashkilot, korxona va muassasalar, kompaniyalar (firmalar), ishlab chiqarish birlashmalari va soha korxonalarida muhandis-mexanik va muhandis texnolog."
                        : leng === "ru"
                        ? "Инженер-механик в государственных и негосударственных организациях, предприятиях и учреждениях, компаниях (фирмах), производственных объединениях и предприятиях промышленности."
                        : "Mechanical engineer in state and non-state organizations, enterprises and institutions, companies (firms), production associations and industry enterprises."}
                    </p>
                  </div>
                  <div className="mashinasozlikCat_1_info_list">
                    <div className="mashinasozlikCat_1_info_list_title">
                      <img src={titleImg} alt="" />
                      <p>{title}</p>
                    </div>
                    <ul>
                      {competencies.map((competency, index) => (
                        <li key={index}>
                          <img src={listLineImg} alt="" />
                          <p>{competency}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {selectedDirection.id === 1 && (
              <div className="FakturaAbsolute">
                <FakultetOne
                  setModalDataValue={setModalDataValue}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </div>
            )}
            {selectedDirection.id === 2 && (
              <div className="FakturaAbsolute">
                <FakultetTwo
                  setModalDataValue={setModalDataValue}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </div>
            )}
            {selectedDirection.id === 3 && (
              <div className="FakturaAbsolute">
                <FakultetThree
                  setModalDataValue={setModalDataValue}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        style={
          selectedDirection?.id === 0
            ? { transform: "translateY(206vw)" }
            : selectedDirection?.id === 1
            ? { transform: "translateY(270vw)" }
            : selectedDirection?.id === 2
            ? { transform: "translateY(210vw)" }
            : selectedDirection?.id === 3
            ? { transform: "translateY(205vw)" }
            : { transform: "translateY(10vw)" }
        }
      >
        <Footer />
      </div>
    </>
  );
};

export default Fakultet;
