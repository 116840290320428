import React, { useContext, useState } from "react";
import bookRed from "../fakultetIcons/bookRed.svg";
import facility from "../fakultetIcons/facility.svg";
import touch from "../fakultetIcons/touch.svg";
import line1 from "../fakultetIcons/line1.svg";
import line2 from "../fakultetIcons/line2.svg";
import line3 from "../fakultetIcons/line3.svg";
import line4 from "../fakultetIcons/line4.svg";
import lineBtn from "../fakultetIcons/lineBtn.svg";
import listLineImg from "../shestnna/listLine.svg";
import userImg from "../shestnna/user.png";
import AppContext from "../../../context/AppContext";

export default function FakultetThree({
  setShowModal,
  showModal,
  setModalDataValue,
}) {
  const { leng } = useContext(AppContext);
  console.log(leng);

  const translations = {
    uz: {
      title: "Bitiruvchilar ega bo‘ladigan kompetensiyalar:",
      competencies: [
        "ilmiy, amaliy tadqiqotlar olib borish, tajriba natijalarini qayta ishlash va ular asosida ilmiy asoslangan xulosalar chiqarish, ilmiy maqolalar tayyorlash va tahrir qilish, ilmiy seminar, konferensiya va simpoziumlarni tashkil etish, o‘tkazish, ilmiy loyihalarni ishlab chiqish ko‘nikmalariga ega boʻlishi;",
        "pedagogik faoliyatida axborot va pedagogik texnologiyalardan foydalanishni bilishi; ta'lim sifati va samaradorligini oshirishga innovatsion yondashishni bilishi;",
        "ilmiy faoliyati natijalari bo‘yicha davlat va xorijda e'lon qilingan loyihalarda ishtirok etish uchun loyiha tayyorlash ko‘nikmalariga ega boʻlishi;",
        "davlat, nodavlat va notijorat tashkilotlari tomonidan e'lon qilingan loyihalarda ishtirok etish uchun loyiha tayyorlash malakalariga ega boʻlishi;",
        "ishlab chiqarishni tashkil etish va boshqarish ko‘nikmalariga ega bo‘lishi; mashinasozlik texnologiyasi ishlab chiqarishni tashkil etish va boshqarish;",
        "mahsulotning sifat darajasini baholash, sifatni boshqarish va boshqarishning samarali kompleks tizimini joriy qilish, mahsulot sifati va raqobatbardoshligini boshqarish jarayonlarini optimizatsiyalash;",
        "mashinasozlikda mashinalar detallarini texnologik qulay va samarador bo‘lgan texnologik jarayonlarini ishlab chiqish va ilmiy asoslash;",
        "Mashinasozlikda ishlab chiqarish va ishlov berish sohasidagi texnologik tizim elementlari: texnologik jihozlar, asbob-uskunalar va ulardan foydalanish, ta'mirlash va sozlash hamda ularga texnik va servis xizmati ko‘rsatish;",
        "texnologik jihozlar, texnologik majmualar va uskunalarni avtomatlashtirish, ishlab chiqarishni boshqarishga taalluqli ilg'or texnologik jarayonlar va kontseptsiyalarni joriy qilish;",
        " mashinasozlik ishlab chiqarishini (texnika va texnologiyalarni) loyihalash sohasida yechilayotgan ilmiy muammolar va topshiriqlarning kontseptual hamda nazariy modellarini ishlab chiqish;",
        "mahalliy va xorijiy sanoat korxonalarida mashinasozlik ishlab chiqarishiga oid jarayonlar hamda hodisalarni xarakterlaydigan koʻrsatkichlarni tahlil qilish va mahalliylashtirish boʻyicha takliflar kiritish. ",
      ],
    },
    ru: {
      title: "Компетенции, которыми будут обладать выпускники:",
      competencies: [
        "иметь умения проводить научные и прикладные исследования, обрабатывать результаты экспериментов и делать на их основе научно обоснованные выводы, готовить и редактировать научные статьи, организовывать и проводить научные семинары, конференции и симпозиумы, разрабатывать научные проекты;",
        "знание использования информационно-педагогических технологий в педагогической деятельности; знание инновационного подхода к повышению качества и эффективности образования;",
        "иметь навыки проектной подготовки для участия в проектах, заявленных в государстве и за рубежом по результатам научной деятельности;",
        "иметь квалификацию проектировщика для участия в проектах, заявленных государственными, негосударственными и некоммерческими организациями;",
        "иметь навыки организации производства и управления; организация и управление машиностроительным технологическим производством;",
        "оценка уровня качества продукции, внедрение эффективной комплексной системы контроля и управления качеством, оптимизация процессов управления качеством и конкурентоспособностью продукции;",
        "разработка и научное обоснование технологических процессов изготовления деталей машин в машиностроении, технологически удобных и эффективных;",
        "Элементы технологической системы в сфере производства и обработки в машиностроении: технологическое оборудование, оборудование и их применение, ремонт и наладка, а также техническое и сервисное обслуживание их;",
        "автоматизация технологического оборудования, технологических комплексов и оборудования, внедрение передовых технологических процессов и концепций, связанных с управлением производством;",
        "осуществление планирования, проектирования и управления производственными предприятиями и организациями,",
        "разработка концептуальных и теоретических моделей научных проблем и задач, решаемых в сфере машиностроительного производства (техники и технологий);",
        "анализ показателей, характеризующих процессы и явления, связанные с производством машиностроения на отечественных и зарубежных промышленных предприятиях, и внесение предложений по локализации.",
      ],
    },
    en: {
      title: "Competencies that graduates will acquire:",
      competencies: [
        "have the skills to conduct scientific and applied research, process experimental results and draw scientifically based conclusions based on them, prepare and edit scientific articles, organize and hold scientific seminars, conferences and symposiums, and develop scientific projects;",
        "Knowledge of the use of information and pedagogical technologies in pedagogical activities; knowledge of innovative approaches to improving the quality and efficiency of education;",
        "have the skills to prepare projects to participate in projects announced in the state and abroad based on the results of their scientific activities;",
        "have project preparation skills to participate in projects announced by state, non-governmental and non-profit organizations;",
        "have the skills to organize and manage production; organize and manage production in mechanical engineering technology;",
        "assessment of product quality, implementation of an effective integrated system of quality management and control, optimization of product quality and competitiveness management processes;",
        "development and scientific substantiation of technologically convenient and efficient technological processes for manufacturing machine parts in mechanical engineering;",
        "Elements of the technological system in the field of production and processing in mechanical engineering: technological equipment, tools and their use, repair and adjustment, as well as technical and service maintenance;",
        "introduction of advanced technological processes and concepts related to automation of technological equipment, technological complexes and equipment, production management;",
        "planning, design and management of production enterprises and organizations,",
        "development of conceptual and theoretical models of scientific problems and tasks being solved in the field of mechanical engineering production design (equipment and technologies);",
        "Analysis of indicators characterizing processes and phenomena related to mechanical engineering production at domestic and foreign industrial enterprises and making proposals for localization.",
      ],
    },
  };

  const { title, competencies } = translations[leng];

  const [boxes, setBoxes] = useState({
    boxesOne: [
      {
        icons: bookRed,
        title:
          leng === "uz"
            ? "O'qitish"
            : leng === "ru"
            ? "Преподавание"
            : "Teaching",
        dec:
          leng === "uz" ? (
            <span className="">
              Barcha ta'lim muassasalarida
              <br />
              <br />
              O‘zbekiston Fanlar Akademiyasida
              <br />
              <br />
              Tadqiqot institutlari
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Во всех учебных заведениях
              <br />
              <br />
              В Академии наук Узбекистана
              <br />
              <br />
              Научно-исследовательских институтах
            </span>
          ) : (
            <span className="">
              In all educational institutions
              <br />
              <br />
              In the Academy of Sciences of Uzbekistan
              <br />
              <br />
              Research institutes
            </span>
          ),
      },
      {
        icons: facility,
        title:
          leng === "uz"
            ? "Ishlab chiqarish faoliyati"
            : leng === "ru"
            ? "Производственная деятельность"
            : "Production activities",
        dec:
          leng === "uz" ? (
            <span className="">
              O'rnatish va ishlatish
              <br />
              <br />
              Sanoat tashkilotlarida ishlash
              <br />
              <br />
              Dizayn, ishlab chiqarish va yig'ish
            </span>
          ) : leng === "ru" ? (
            <span className="">
              Наладка и эксплуатация
              <br />
              <br />
              Работа в промышленных организациях
              <br />
              <br />
              Проектирование, производство и сборка
            </span>
          ) : (
            <span className="">
              Setup and operation
              <br />
              <br />
              Work in industrial organizations
              <br />
              <br />
              Design, production and assembly
            </span>
          ),
      },
    ],
    boxesTwo: [
      {
        icons: touch,
        title:
          leng === "uz"
            ? "Davlat va iqtisodiy boshqaruv"
            : leng === "ru"
            ? "Государственное и хозяйственное управление"
            : "Public and economic management",
        dec:
          leng === "uz" ? (
            <span className="">
              Davlat organlarida
              <br />
              <br />
              Biznes tuzilmalarida
              <br />
              <br />
              Aktsiyadorlik jamiyatlarida
            </span>
          ) : leng === "ru" ? (
            <span className="">
              В органах госуправления
              <br />
              <br />
              В хозяйственных структурах
              <br />
              <br />В совместных акционерных обществах
            </span>
          ) : (
            <span className="">
              In government bodies
              <br />
              <br />
              In business structures
              <br />
              <br />
              In joint stock companies
            </span>
          ),
      },
    ],
  });

  return (
    <div className="FakultetOne">
      <div className="FakultetOneSection1">
        <h1>
          {leng === "uz" ? (
            <>
              Kasbiy <br /> faoliyatining <br /> sohalari:
            </>
          ) : leng === "ru" ? (
            <>
              Сферы <br /> профессиональной <br /> деятельности:
            </>
          ) : (
            <>
              Areas of <br /> professional <br /> activity:
            </>
          )}
        </h1>
        <div className="FakultetThreeBoxes">
          <div className="FakultetThreeBoxesOne">
            {boxes.boxesOne.map((item) => (
              <div className="FakultetThreeBox">
                <img src={item.icons} alt="" />
                <p>{item.title}</p>
                {item.dec}
              </div>
            ))}
          </div>
          <div className="FakultetThreeBoxesOne">
            {boxes.boxesTwo.map((item) => (
              <div className="FakultetThreeBox FakultetThreeBoxesTwo">
                <img src={item.icons} alt="" />
                <p>{item.title}</p>
                {item.dec}
              </div>
            ))}
          </div>
        </div>
        <div className="FakultetOneSectio1Borders">
          <img src={line1} alt="" className="line1" />
          <img src={line2} alt="" className="line2" />
          <img src={line3} alt="" className="line3" />
          <img src={line4} alt="" className="line4" />

          <img src={lineBtn} alt="" className="lineBtn" />

          <button
            className="modalButtonOrange modalBtnThree"
            onClick={() => setShowModal(true) + setModalDataValue(4)}
          >
            <p>
              {leng === "uz"
                ? "Fakultet haqida batafsil"
                : leng === "ru"
                ? "Подробнее о факультете"
                : "More about the faculty"}
            </p>
          </button>
          <div className="cloudF"></div>
        </div>
      </div>
      <div className="mashinasozlikCat_1_info_head mashinasozlikCat_1_info_head2 mashinasozlikCat_1_info_head4">
        <h1>
          {leng === "uz" ? (
            <>
              <b>Kasbiy</b> faoliyatlarining obyektlari.
            </>
          ) : leng === "ru" ? (
            <>
              <b>Объекты</b> профессиональной деятельности.
            </>
          ) : (
            <>
              <b>Objects</b> of professional activity.
            </>
          )}
        </h1>
        <p>
          {leng === "uz"
            ? "oliy ta'lim, qayta tayyorlash va malaka oshirish, professional ta'lim muassasalarida pedagogik faoliyat; Fanlar akademiyasi va tarmoq ilmiy-tadqiqot institutlari va markazlarida hamda oliy ta'lim muassasalarida ilmiy-tadqiqot faoliyati va jarayonlar; davlat boshqaruvi va uning turli hududiy bo‘linmalari; texnologik jarayonlar, texnologik jarayonlarni loyihalash va avtomatlashtirilgan loyihalash tizimlari bilan shug'ullanuvchi korxona va muassasalar; texnologik tizim elementlari: texnologik jihozlar, asbob-uskunalar va ulardan foydalanish, ta'mirlash va sozlash hamda ularga texnik va servis xizmati ko‘rsatish muassasalari; texnologik jihozlar, texnologik majmualar va uskunalarning avtomatlashtirish tizimlarini ishlab chiqaruvchi korxona va muassasalar; ilmiy va ishlab chiqarish faoliyatini texnikaviy ta'minlash vositalari."
            : leng === "ru"
            ? "педагогическая деятельность в высших учебных заведениях, учреждениях переподготовки и профессионального образования; Научно-исследовательская деятельность и процессы в Академии наук и сетевых научно-исследовательских институтах и ​​центрах и высших учебных заведениях; государственная администрация и ее различные территориальные подразделения; предприятия и учреждения, занимающиеся технологическими процессами, проектированием технологических процессов и системами автоматизированного проектирования; элементы технологической системы: технологическое оборудование, оборудование и средства их использования, ремонта и наладки, а также технические и сервисные средства для них; предприятия и учреждения, производящие технологическое оборудование, технологические комплексы и системы автоматизации оборудования; средства технического обеспечения научной и производственной деятельности. "
            : "pedagogical activities in higher education, retraining and advanced training, and professional educational institutions; Research activities and processes at the Academy of Sciences and industry research institutes and centers, as well as higher education institutions; state administration and its various territorial divisions; enterprises and institutions engaged in technological processes, technological process design and automated design systems; technological system elements: technological equipment, tools and institutions for their operation, repair and adjustment, as well as technical and service services; enterprises and institutions producing technological equipment, technological complexes and automation systems for equipment; means of technical support for scientific and production activities."}
        </p>
      </div>
      <div className="mashinasozlikCat_1_info_list mashinasozlikCat_1_info_list2 mashinasozlikCat_1_info_list4">
        <div className="mashinasozlikCat_1_info_list_title">
          <p>{title}</p>
        </div>
        <img id="userImgThereFakultet" src={userImg} alt="" />
        <ul>
          {competencies.map((competency, index) => (
            <li key={index}>
              <img src={listLineImg} alt="" />
              <p>{competency}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
