import "./navbar.css";
import logo from "../../image/ranchLogo.svg";
import { MdArrowDropDown } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import { MdSearch } from "react-icons/md";
import userIcon from "../../image/userLogin.svg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../loading/Loading"; // Make sure the correct path to Loading component
import uzFlag from "../../image/uzFlag.png";
import ruFlag from "../../image/ruFlag.png";
import enFLag from "../../image/enFlag.png";

export default function Navbar({ leng, setLeng }) {
  const [isOpen, setIsOpen] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const translations = {
    ru: {
      university: "Университет",
      about: "Об Университете",
      departments: "Факультеты",
      centers: "Центры и Отделы",
      rectorate: "Ректорат",
      rules: "Кодекс внутреннего трудового порядка университета",
      ethics: "Кодекс поведения",
      charter: "Устав Университета",
      abutrient: "Абитуриент",
      fullTime: "Очное направление образования",
      fullTimeCourse: "Название курсов очного обучения (срок обучения 4 года)",
      correspondence: "Заочное направление образования",
      onlineAdmission: "Онлайн подача документов",
      master: "Магистратура",
      library: "Онлайн библиотека",
      jurnal: "Научный электронный журнал университета",
      weHave: "У нас",
      teachers: "Профессиональных Учителей",
      popularFields: "Востребованных Направлений",
      coolStudents: "Крутых Студентов",
      studentLife: "Жизнь студентов",
      onlineAdmissionPrompt:
        "Готовитесь к поступлению в университет? Заполните форму прямо сейчас и начните свой путь к высшему образованию.",
      submissionDocuments: "Подача документов",
      resources: "Ресурсы",
      eLibrary: "Электронная библиотека",
      fieldsOfStudy: "НАПРАВЛЕНИЯ ОБУЧЕНИЯ",
      moreNews: "Больше новостей",
      details: "Подробно",
      newCampusDevelopment:
        "Создание новых учебных корпусов и общежитий. Расширение кампуса для улучшения условий обучения и проживания студентов.",
      sportsInfrastructure:
        "Развитие спортивной инфраструктуры. Построение современных спортивных комплексов и площадок для содействия физическому развитию студентов.",
      advancedTechLearning:
        "Внедрение передовых технологий обучения. Использование виртуальной реальности и искусственного интеллекта для создания инновационных образовательных программ.",
      internationalCooperation:
        "Расширение международного сотрудничества. Увеличение числа программ обмена и двойных дипломов с ведущими мировыми университетами.",
      partners: "Наши Партнеры",
      news: "Новости",
      publicEvents: "Публичные мероприятия",
      internationalRelations: "Международные отношения",
      loginToAccess: "Войдите в систему для доступа",
    },
    uz: {
      university: "Universitet",
      about: "Universitet haqida",
      departments: "Fakultetlar",
      centers: "Markaz va boʻlimlar",
      rectorate: "Rektorat",
      rules: "Universitet ichki mehnat tartib qoidalar",
      ethics: "Universitet odob-axloq qoidalari",
      charter: "Universitet nizomi",
      abutrient: "Abituriyent",
      fullTime: "Kunduzgi ta’lim yoʻnalishlari",
      fullTimeCourse:
        "Kunduzgi ta’lim yoʻnalishlari nomi (oʻqish muddati 4 yil)",
      correspondence: "Sirtqi taʻlim yoʻnalishlari",
      onlineAdmission: "Onlayn ariza topshirish",
      master: "Magistratura",
      library: "Onlayn kutubxona",
      jurnal: "Universitet ilmiy elektron jurnali",
      weHave: "Bizda",
      teachers: "Professional oʻqituvchilar",
      popularFields: "Talabgor yoʻnalishlar",
      coolStudents: "Ajoyib talabalar",
      studentLife: "Talabalar hayoti",
      onlineAdmissionPrompt:
        "Universitetga kirishga tayyormisiz? Hoziroq shaklni toʻldiring va oliy taʼlimga sayohatingizni boshlang.",
      submissionDocuments: "Hujjatlarni topshirish",
      resources: "Resurslar",
      eLibrary: "Elektron kutubxona",
      fieldsOfStudy: "TA’LIM YOʻNALISHLARI",
      moreNews: "Koʻproq yangiliklar",
      details: "Batafsil",
      newCampusDevelopment:
        "Yangi o'quv binolari va yotoqxonalarni barpo etish. Talabalarning o‘qish va yashash sharoitlarini yaxshilash maqsadida kampusni kengaytirish.",
      sportsInfrastructure:
        "Sport infratuzilmasini rivojlantirish. O‘quvchilarning jismoniy rivojlanishiga ko‘maklashish maqsadida zamonaviy sport majmualari va maydonchalari barpo etilmoqda.",
      advancedTechLearning:
        "Ta’limning ilg’or texnologiyalarini joriy etish. Innovatsion ta’lim dasturlarini yaratish uchun virtual haqiqat va sun’iy intellektdan foydalanish.",
      internationalCooperation:
        "Xalqaro hamkorlikni kengaytirish. Dunyoning yetakchi universitetlari bilan almashinuv va qoʻsh diplom dasturlari sonini koʻpaytirish.",
      partners: "Bizning hamkorlar",
      news: "Yangiliklar",
      publicEvents: "Ommaviy tadbirlar",
      internationalRelations: "Xalqaro munosabatlar",
      loginToAccess: "Koʻrish uchun tizimga kiring",
    },
    en: {
      university: "University",
      about: "About University",
      departments: "Faculties",
      centers: "Centers and other departments",
      rectorate: "University Rectorate",
      rules: "BEHAVIORAL CODE",
      ethics: "University Code of Conduct",
      charter: "University charter",
      abutrient: "Applicant",
      fullTime: "Full-time education programs",
      fullTimeCourse: "Name of full-time courses (4 years of study)",
      correspondence: "Correspondence education programs",
      onlineAdmission: "Online admission",
      master: "Master's degree",
      library: "Online library",
      jurnal: "University scientific e-journal",
      weHave: "We have",
      teachers: "Professional teachers",
      popularFields: "Popular areas of study",
      coolStudents: "Cool Students",
      studentLife: "Students' life",
      onlineAdmissionPrompt:
        "Are you preparing to enter university? Fill out the form now and start your journey to higher education.",
      submissionDocuments: "Submission of documents",
      resources: "Resources",
      eLibrary: "E-library",
      fieldsOfStudy: "FIELDS OF STUDY",
      moreNews: "More news",
      details: "More detail",
      newCampusDevelopment:
        "Creation of new academic buildings and dormitories. Expansion of the campus to improve the conditions for students to study and live.",
      sportsInfrastructure:
        "Development of sports infrastructure. Construction of modern sports complexes and grounds to promote physical development of students.",
      advancedTechLearning:
        "Implementation of advanced learning technologies. Using virtual reality and artificial intelligence to create innovative educational programs.",
      internationalCooperation:
        "Expansion of international cooperation. Increase in the number of exchange programs and double degrees with leading world universities.",
      partners: "Our Partners",
      news: "News",
      publicEvents: "Public events",
      internationalRelations: "International relations",
      loginToAccess: "Log in to the system for access",
    },
  };

  const currentTranslations = translations[leng];

  // Event listener to close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu_drop")) {
        setIsOpen(0);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLanguageChange = (lang) => {
    setLoading(true); // Show loading spinner
    setTimeout(() => {
      setLeng(lang); // Set the selected language
      setIsMenuOpen(false); // Close the menu
      setLoading(false); // Hide loading spinner
    }, 1000); // Simulated delay
  };

  const languageLabels = {
    uz: "O‘zbekcha",
    ru: "Русский",
    en: "English",
  };

  // if (loading) {
  //     return <Loading />;
  // }

  return (
    <nav>
      <div className="nav_left">
        <Link to={"/"}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="nav_right">
        <div className="menu_drops">
          <div className="menu_drop">
            <button
              onClick={() => setIsOpen(isOpen !== 1 ? 1 : 0)}
              className="menu_drop_btn1"
            >
              <p>{currentTranslations.university}</p>
              <MdArrowDropDown />
            </button>
            {isOpen === 1 && (
              <div>
                <Link to={"/about"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.about}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/department"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.departments}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/departments"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.centers}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/rektorat"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.rectorate}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/rules"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.rules}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/rulesEtika"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.ethics}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/regulation"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.charter}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/tashkiliytuzilmasi"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Universitet tashkiliy tuzilmasi"
                        : leng == "ru"
                        ? "Cтруктура Университета"
                        : "Structure of the University"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/qoidalar"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Ta’lim tog‘risidagi hujjatlar"
                        : leng == "ru"
                        ? "Документы об образовании"
                        : "Educational documents"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link
                  to={
                    leng == "uz"
                      ? "https://forms.gle/4ktgNKWQ9xPydqvG9"
                      : leng == "ru"
                      ? "https://forms.gle/SmcU4iZZpy42wK556"
                      : "https://forms.gle/SmcU4iZZpy42wK556"
                  }
                >
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "So'rovnoma"
                        : leng == "ru"
                        ? "Опрос"
                        : "Survey for students"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className="menu_drop">
            <button
              onClick={() => setIsOpen(isOpen !== 2 ? 2 : 0)}
              className="menu_drop_btn1"
            >
              <p>{currentTranslations.abutrient}</p>
              <MdArrowDropDown />
            </button>
            {isOpen === 2 && (
              <div>
                <Link to={"/fakultet"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Fakultetlar"
                        : leng == "ru"
                        ? "Факультеты"
                        : "Faculties"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/kunduzgi"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.fullTime}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/sirtqi"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.correspondence}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"https://qabul.utu-ranch.uz/"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.onlineAdmission}</p>
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className="menu_drop">
            <button
              onClick={() => setIsOpen(isOpen !== 3 ? 3 : 0)}
              className="menu_drop_btn1"
            >
              <p>{currentTranslations.master}</p>
              <MdArrowDropDown />
            </button>
            {isOpen === 3 && (
              <div>
                <Link to={"/magistratura"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.master}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link
                  to={
                    "https://docs.google.com/forms/d/e/1FAIpQLSc-46WYp8t2Q2IWAZSd19tW_hJigel2FhzFIdX2pFdxkA-FGQ/viewform?usp=header"
                  }
                >
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Magistratura uchun so‘rovnoma"
                        : leng == "ru"
                        ? "Опросник для магистратуры"
                        : "Survey for Master's degree"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className="menu_drop">
            <button
              onClick={() => setIsOpen(isOpen !== 4 ? 4 : 0)}
              className="menu_drop_btn1"
            >
              <p>{currentTranslations.resources}</p>
              <MdArrowDropDown />
            </button>
            {isOpen === 4 && (
              <div>
                <Link to={"https://edu.utu-ranch.uz"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.library}</p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"https://ranchjournal.uz"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>{currentTranslations.jurnal}</p>
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className="menu_drop">
            <button
              onClick={() => setIsOpen(isOpen !== 5 ? 5 : 0)}
              className="menu_drop_btn1"
            >
              <p>
                {leng == "uz"
                  ? "E'lonlar"
                  : leng == "ru"
                  ? "Объявления"
                  : "Announcements"}
              </p>
              <MdArrowDropDown />
            </button>
            {isOpen === 5 && (
              <div>
                <Link to={"https://tdkongre.com/en/"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Konferensiya"
                        : leng == "ru"
                        ? "Конференция"
                        : "Conference"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/elonlar"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "E'lonlar"
                        : leng == "ru"
                        ? "Объявления"
                        : "Announcements"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
                <Link to={"/qqk"}>
                  <button onClick={() => setIsOpen(0)}>
                    <p>
                      {leng == "uz"
                        ? "Maqolalar"
                        : leng == "ru"
                        ? "Статьи"
                        : "Articles"}
                    </p>
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="search">
          <button>
            <MdSearch />
          </button>
        </div>

        <div className="langugeSelect">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <p>{languageLabels[leng]}</p> <MdArrowDropDown />
          </button>
          {isMenuOpen && (
            <div className="langugeSelect_menu">
              <button onClick={() => handleLanguageChange("uz")}>
                <img />
                O‘zbekcha <img src={uzFlag} alt="" />{" "}
              </button>
              <button onClick={() => handleLanguageChange("ru")}>
                <img src="" />
                Русский <img src={ruFlag} alt="" />{" "}
              </button>
              <button onClick={() => handleLanguageChange("en")}>
                <img />
                English <img src={enFLag} alt="" />{" "}
              </button>
            </div>
          )}
        </div>
        <Link to={"https://student.utu-ranch.uz/dashboard/login"}>
          <button className="login">
            <p>
              {leng === "uz"
                ? "Kirish"
                : leng === "ru"
                ? "Bойти"
                : leng === "en"
                ? "Login"
                : null}
            </p>
            <div>
              <img src={userIcon} alt="User Icon" />
            </div>
          </button>
        </Link>
      </div>
    </nav>
  );
}
